import React from "react";
import "./UHTFooter.css";

function UHTFooter() {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <>
    <section className='space-div-block'></section>
      <section className="footer-light">
        <div className="container-2">
          <div className="footer-wrapper-two">
            <div className="w-layout-vflex footer-brand">
              <a href="####" className="footer-brand w-inline-block">
                <img src={require("../images/footer-logo.png")} alt="" />
              </a>
              <p className="paragraph footer-small">
                Proof-of-Health for a Smarter, <br />
                Healthier Future
              </p>
              <div className="footer-social-block-two">
                <a
                  href="https://discord.com/invite/Jv98rZFeSE"
                  target="blank"
                  className="footer-social-link w-inline-block"
                >
                  <img
                    src={require("../images/discord-icon.png")}
                    alt=""
                    style={{ height: "18px" }}
                  />
                </a>
                <a
                  href="https://telegram.me/+0p0JIMiSh0QxMmM1"
                  target="blank"
                  className="footer-social-link w-inline-block"
                >
                  <img
                    src={require("../images/telegram-icon.png")}
                    style={{ height: "18px" }}
                    alt=""
                  />
                </a>
                <a
                  href="https://x.com/UHTofficial"
                  target="blank"
                  className="footer-social-link w-inline-block"
                >
                  <img
                    src={require("../images/x-icom.png")}
                    style={{ height: "18px" }}
                    alt=""
                  />
                </a>
              </div>
            </div>
            <div className="footer-block-two">
              <div className="footer-title">Sitemap</div>
              <a href="###" 
              onClick={(e) => {
                e.preventDefault();
                scrollToSection('vision');
              }} className="footer-link-two">
                Vision
              </a>
              <a href="https://uht.gitbook.io/universal-health-token/" target="blank" className="footer-link-two">
                Whitepaper
              </a>
              <a href="####" className="footer-link-two"
              onClick={(e) => {
                e.preventDefault();
                scrollToSection('value');
              }} 
              >
                Learn About DeSci
              </a>
              <a className="footer-link-two"
              
              onClick={(e) => {
                e.preventDefault();
                scrollToSection('our-partners');
              }}     href="####"     >
                Our Partners
              </a>
              <a href="####" className="footer-link-two">
                FoG Foundation
              </a>
            </div>
            <div className="footer-block-two">
              <div className="footer-title">Contact Us</div>
              <p className="footer-link-two mb-0">
                info@forceofgood.xyz
              </p>
              <p  className="footer-link-two mb-0">
                Force of Good Ltd., Jalya Place, 2nd Floor,
                <br />
                Road Town, Tortola, British Virgin Island
                <br />
                VG1110
              </p>
            </div>
          </div>
          <div className="footer-divider-two"></div>
          <div className="footer-bottom">
            <div className="footer-copyright">
              © 2024 Force of Good Foundation. All rights reserved
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default UHTFooter;
