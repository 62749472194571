import React, { useState, useEffect } from 'react';

import './UHTWorks.css';

function UHTWorks() {

    const [isDesktop, setIsDesktop] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 980);  // You can adjust the width threshold as needed
    };

    handleResize();  // Check initial screen size
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize); // Clean up on unmount
  }, []);

  return (
    <>
        {/* <section className='space-div-block'></section> */}

        <section className='UHTWorks'>
            <div className='container'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <h1 className='works-title' data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="300">How It Works</h1>
                                    <h2 className='works-subtitle' data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="500">How Proof-of-Health Works</h2>
                                </div>
                           </div>
                           <div className='row align-items-center'>
                                <div className='col-md-4'>
                                    <div className='row align-items-center'>
                                        <div className='col-auto px-0'>
                                            <img src={require('../images/icon-work-1.png')} className="img-fluid" alt="" data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="400"/>
                                        </div>
                                        <div className='col'>
                                            <p className='content-text mb-0' data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="600">
                                                <span>Earn Rewards:</span>  <br/>
                                                Receive dynamic incentives for preventive health actions.
                                            </p>
                                        </div>
                                    </div>

                                    <div className='row align-items-center mt-5'>
                                        <div className='col-auto px-0'>
                                            <img src={require('../images/icon-work-2.png')} className="img-fluid" alt="" data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="400"/>
                                        </div>
                                        <div className='col'>
                                            <p className='content-text mb-0' data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="600">
                                                <span>Track Your Health:</span>  <br/>
                                                Sync wearables or health apps to securely log data on-chain.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-md-4 text-center'>
                                <img src={require('../images/logo-uht-box.jpg')} className="img-fluid d-none d-md-block" alt="" data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="400"/>
                                </div>

                                <div className='col-md-4'>
                                    <div className='row align-items-center'>
                                        <div className='col-auto px-0'>
                                            <img src={require('../images/icon-work-3.png')} className="img-fluid" alt="" data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="400"/>
                                        </div>
                                        <div className='col'>
                                            <p className='content-text mb-0' data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="600">
                                                <span>Shop Smart:</span>  <br/>
                                                Use tokens for products and services that enhance your health.
                                            </p>
                                        </div>
                                    </div>

                                    <div className='row align-items-center mt-5'>
                                        <div className='col-auto px-0'>
                                            <img src={require('../images/icon-work-4.png')} className="img-fluid" alt="" data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="400"/>
                                        </div>
                                        <div className='col'>
                                            <p className='content-text mb-0' data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="600">
                                                <span>Drive Innovation:</span>  <br/>
                                                Share anonymized data with Decentralized Science projects.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                
                           </div>
            </div>
        </section>
    </>
  );
}


export default UHTWorks;
