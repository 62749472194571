import { Routes, Route, Navigate } from 'react-router-dom'
import './App.css';
import UHTLanding from './pages/UHTLanding';

function App() {
  return (
    <>
    <Routes>
      <Route path="/">
        <Route exact path="/" element={<Navigate to="/homepage" />} />
        <Route path="/homepage" element={<UHTLanding />} />
      </Route>
     </Routes>
    </>
  );
}
export default App;
