import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, EffectFade, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';
import './UHTHealthcare.css';

function UHTHealthcare() {

    const [isDesktop, setIsDesktop] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 980);  // You can adjust the width threshold as needed
    };

    handleResize();  // Check initial screen size
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize); // Clean up on unmount
  }, []);


  return (
    <>
        {/* <section className='space-div-block'></section> */}

        <section className='UHTHealthcare'>
            <div className='container'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <h1 className='healthcare-title' data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="300">Unlocking a New Era in Healthcare</h1>
                                </div>
                           </div>

                           <div className='row align-items-center'>
                                <div className='col-md-12 text-center'>
                                <Swiper
                                    spaceBetween={0}
                                    slidesPerView={1}
                                    effect="fade"
                                    fadeEffect={{ crossFade: true }} // Smooth fade effect
                                    navigation={{
                                      nextEl: '.custom-next-healthacare',
                                      prevEl: '.custom-prev-healthacare',
                                    }}
                                    autoplay={{
                                      delay: 3000, // Auto slide every 3 seconds
                                      disableOnInteraction: false,
                                    }}
                                    modules={[Navigation, EffectFade, Autoplay]}
                                    loop={true}
                                    speed={1000} // Adjust speed for smoother fade transitions
                                    className="custom-swiper-healthacare"
                                    >
                                    <SwiperSlide className='grey-box'>
                                        <div className='row align-items-center'>
                                            <div className='col-md-5 text-center'>
                                                <img src={require('../images/slider-Healthcare-1.jpg')} className="img-fluid" alt=""  data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="500"/>
                                            </div>
                                            <div className='col-md-7 px-5'>
                                                <h2 className='healthcare-subtitle' data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="500">Blockchain-Powered Data Lake</h2>
                                                <p className='content-text mb-0' data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="600">Enable personalized solutions by consenting to share preventive health data with trusted providers, unlocking tailored insurance plans, bespoke treatments, and revolutionary health innovations.</p>
                                            </div>
                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide className='grey-box'>
                                        <div className='row align-items-center'>
                                            <div className='col-md-5 text-center'>
                                                <img src={require('../images/slider-Healthcare-2.jpg')} className="img-fluid" alt=""  data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="500"/>
                                            </div>
                                            <div className='col-md-7 px-5'>
                                                <h2 className='healthcare-subtitle' data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="500">Leveraging Wearable Tech</h2>
                                                <p className='content-text mb-0' data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="600">With millions of data points generated daily by devices and apps, UHT transforms raw health data into actionable insights, fueling a smarter, healthier, and more connected global population.</p>
                                            </div>
                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide className='grey-box'>
                                        <div className='row align-items-center'>
                                            <div className='col-md-5 text-center'>
                                                <img src={require('../images/slider-Healthcare-3.jpg')} className="img-fluid" alt=""  data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="500"/>
                                            </div>
                                            <div className='col-md-7 px-5'>
                                                <h2 className='healthcare-subtitle' data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="500">Fighting Chronic Diseases</h2>
                                                <p className='content-text mb-0' data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="600">Chronic diseases are the top global killers. UHT’s Proof-of-Health protocol empowers prevention by identifying risks early and enabling precision care to save millions of lives.</p>
                                            </div>
                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide className='grey-box'>
                                        <div className='row align-items-center'>
                                            <div className='col-md-5 text-center'>
                                                <img src={require('../images/slider-Healthcare-4.jpg')} className="img-fluid" alt=""  data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="500"/>
                                            </div>
                                            <div className='col-md-7 px-5'>
                                                <h2 className='healthcare-subtitle' data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="500">Advancing Biomedical Breakthroughs</h2>
                                                <p className='content-text mb-0' data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="600">UHT connects anonymized user data with cutting-edge research projects, driving groundbreaking discoveries while rewarding users for their contributions.</p>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                
                                    {/* Additional SwiperSlide components can go here */}

                                    <div className="custom-prev-healthacare"><i class="bi bi-arrow-left"></i></div>
                                    <div className="custom-next-healthacare"><i class="bi bi-arrow-right"></i></div>
                                    </Swiper>
                                </div>
                           </div>

                           
            </div>
        </section>
    </>
  );
}


export default UHTHealthcare;
