import React, { useState, useEffect } from 'react';
import './UHTBanner.css';

function UHTBanner() {

  const [isDesktop, setIsDesktop] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 980);  // You can adjust the width threshold as needed
    };

    handleResize();  // Check initial screen size
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize); // Clean up on unmount
  }, []);

  return (
    <>
      <header className="UHTBanner" id="home">
            <div className='bg-image' data-aos={isDesktop ? 'fade-in' : undefined}  data-aos-delay="100"></div>
            <div className="container">
                <div className="row align-items-center">
                    <div className='col-md-6 pe-md-5'>
                        <h1 className='title' data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="400">The Future of<br/> Health Starts Here</h1>
                        <h2 className='subtitle' data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="500">UHT’s <span>Proof-of-Health protocol</span> transforms how healthcare is delivered—making preventive care accessible, data-driven, and rewarding while empowering a new era of <span>on-chain health innovation</span>.</h2>
                        <button type="button" className="btn btn-success btn-book-demo me-3" data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="600" >Learn More</button>
                        <button type="button" className="btn btn-success btn-saving-calculator" data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="600" data-bs-toggle="modal" data-bs-target="#uhtConnetWalletModal">Connect Wallet</button>
                    </div>
                    
                </div>
            </div>
      </header>


      
    </>
  );
}


export default UHTBanner;
