import React from 'react';
import './UHTeModulePopup.css';

function UHTeModulePopup() {
  

  return (
    <>
        

        <div className="modal fade" id="uhtConnetWalletModal" tabindex="-1" aria-labelledby="uhtConnetWalletModalLabel" data-bs-backdrop="static" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className="modal-content" style={{borderRadius:"30px"}}>
            <div className="modal-header" style={{border:"0px"}}>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body text-center mb-3 pt-0">
            <section className='UHTeModulePopup'>
            <div className='container'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <h1 className='challenge-title' >Connect Wallet</h1>
                                    <h2 className='challenge-subtitle' >Coming Soon</h2>
                                </div>
                           </div>
                          
                           <div className='row'>
                                <div className='col-md-12 text-center'>
                                    <button type="button" className="btn btn-calculator mt-3">Connect</button>
                                </div>
                           </div>

                         
            </div>
        </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}


export default UHTeModulePopup;
