import React, { useState, useEffect } from 'react';

import './UHTAction.css';

function UHTAction() {

  const [isDesktop, setIsDesktop] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 980);  // You can adjust the width threshold as needed
    };

    handleResize();  // Check initial screen size
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize); // Clean up on unmount
  }, []);

  return (
    <>
        <section className='space-div-block'></section>

        <section className='UHTAction'>
            <div className='container'>
                
            
            <div className='box-height'>
                        <div style={{width:"100%"}}>
                          <div className='row'>
                                <div className='col-md-12'>
                                    <h1 className='action-title' data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="300">How It action</h1>
                                </div>
                           </div>
                        </div>
                        <div style={{width:"100%"}}>
                           <div className='row align-items-center'>
                                <div className='col-md-5'>
                                <h2 className='action-subtitle' data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="500">Join the Proof-of-Health Revolution</h2>
                                <p className='content-text mb-4' data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="600">Be part of a decentralized healthcare system that rewards health, empowers research, and transforms global well-being </p>
                                <button type="button" className="btn btn-success btn-get-started me-3" data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="600" >Get Started</button>
                                </div>
                           </div>
                           </div>
            </div>
            </div>
        </section>
    </>
  );
}


export default UHTAction;
