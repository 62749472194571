import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import UHTNavbar from '../componenents/UHTNavbar';
import UHTBanner from '../componenents/UHTBanner';
import UHTVision from '../componenents/UHTVision';
import UHTValue from '../componenents/UHTValue';
import UHTWorks from '../componenents/UHTWorks';
import UHTAction from '../componenents/UHTAction';
import UHTHealthcare from '../componenents/UHTHealthcare';
import UHTPartners from '../componenents/UHTPartners';
import UHTAdvisors from '../componenents/UHTAdvisors';
import UHTFooter from '../componenents/UHTFooter';
import UHTeModulePopup from '../componenents/UHTeModulePopup';

import './UHTLanding.css';

function UHTLanding() {
  
    useEffect(() => {
        AOS.init({
          duration: 1000, // animation duration
          once: false, // whether animation should only happen once
        });
    }, []);

  return (
    <>
    <main className='UHTLanding'>
        <UHTNavbar/> 
        <UHTBanner/>
        <UHTVision/>
        <UHTValue/>
        <UHTWorks/>
        <UHTHealthcare/>
        <UHTAction/>
        <UHTPartners/>
        <UHTAdvisors/>
        <UHTFooter/>
        <UHTeModulePopup/>
    </main>
    </>
  );
}

export default UHTLanding;
