import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, EffectFade, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';
import './UHTValue.css';

function UHTValue() {

    const [isDesktop, setIsDesktop] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 980);  // You can adjust the width threshold as needed
    };

    handleResize();  // Check initial screen size
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize); // Clean up on unmount
  }, []);

  return (
    <>
        <section className='space-div-block' id='value'></section>

        <section className='UHTValue'>
            <div className='container'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <h1 className='value-title' data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="300">Transforming Health into Value</h1>
                                </div>
                           </div>

                           <div className='row align-items-center'>
                                <div className='col-md-12 text-center'>
                                <Swiper
                                    spaceBetween={0}
                                    slidesPerView={1}
                                    effect="fade"
                                    fadeEffect={{ crossFade: true }} // Smooth fade effect
                                    navigation={{
                                      nextEl: '.custom-next',
                                      prevEl: '.custom-prev',
                                    }}
                                    autoplay={{
                                      delay: 3000, // Auto slide every 3 seconds
                                      disableOnInteraction: false,
                                    }}
                                    modules={[Navigation, EffectFade, Autoplay]}
                                    loop={true}
                                    speed={1000} // Adjust speed for smoother fade transitions
                                    className="custom-swiper"
                                    >
                                    <SwiperSlide>
                                        <div className='row align-items-center'>
                                                <div className='col-md-5'>
                                                    <h2 className='value-subtitle' data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="500">User-Owned Health Data</h2>
                                                    <p className='content-text mb-0' data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="600">UHT ensures your health data is securely stored on-chain, putting you in control to share it only when it benefits you—whether for earning rewards, accessing personalized care, or unlocking better insurance options.</p>
                                                </div>
                                                <div className='col-md-7 text-center'>
                                                <img src={require('../images/slider-Health-Data.jpg')} className="img-fluid" alt=""  data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="500"/>
                                                </div>
                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <div className='row align-items-center'>
                                                <div className='col-md-5'>
                                                    <h2 className='value-subtitle' data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="500">Gamified Preventive Healthcare</h2>
                                                    <p className='content-text mb-0' data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="600">Turn wellness into a rewarding journey. UHT’s Proof-of-Health protocol gamifies everyday health actions, incentivizing users with dynamic rewards that improve their well-being while advancing healthcare innovation.</p>
                                                </div>
                                                <div className='col-md-7 text-center'>
                                                <img src={require('../images/slider-Gamified-Preventive-Healthcare.jpg')} className="img-fluid" alt=""  data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="500"/>
                                                </div>
                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <div className='row align-items-center'>
                                                <div className='col-md-5'>
                                                    <h2 className='value-subtitle' data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="500">Health Marketplace</h2>
                                                    <p className='content-text mb-0' data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="600">With over 300+ e-commerce partners, your earned UHT tokens can be used to purchase fitness programs, wellness treatments, and premium health products—creating a seamless loop of healthier living and spending.</p>
                                                </div>
                                                <div className='col-md-7 text-center'>
                                                <img src={require('../images/slider-Health-Marketplace.jpg')} className="img-fluid" alt=""  data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="500"/>
                                                </div>
                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <div className='row align-items-center'>
                                                <div className='col-md-5'>
                                                    <h2 className='value-subtitle' data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="500">Decentralized Science (DeSci)</h2>
                                                    <p className='content-text mb-0' data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="600">Empower  by sharing anonymized health data with projects you believe in, advancing science while earning rewards for fueling the future of healthcare innovation.</p>
                                                </div>
                                                <div className='col-md-7 text-center'>
                                                <img src={require('../images/slider-Decentralized-Science.jpg')} className="img-fluid" alt=""  data-aos={isDesktop ? 'fade-up' : undefined} data-aos-delay="500"/>
                                                </div>
                                        </div>
                                    </SwiperSlide>
                                
                                    {/* Additional SwiperSlide components can go here */}

                                    <div className="custom-prev"><i class="bi bi-arrow-left"></i></div>
                                    <div className="custom-next"><i class="bi bi-arrow-right"></i></div>
                                    </Swiper>
                                </div>
                           </div>

                           
            </div>
        </section>
    </>
  );
}


export default UHTValue;
